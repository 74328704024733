import React, {useState, useEffect} from 'react';
import {LineChart} from '@mui/x-charts/LineChart';
import {Grid, Typography, Box, CircularProgress, Select, MenuItem} from "@mui/material";
import {mangoFusionPalette} from '@mui/x-charts/colorPalettes';
import { colorCategories } from './colors';

const LineQpsChart = ({dateLabels, nameTitle, fullData}) => {

    const [loading, setLoading] = useState(false);
    const [region, setRegion] = useState('EU');

    const [dataEu, setDataEu] = useState([]);
    const [dataUs, setDataUs] = useState([]);
    const [dataAp, setDataAp] = useState([]);

    const [currentData, setCurrentData] = useState([]);

    const handleChange = (event) => {
        const selectedRegion = event.target.value;
        setRegion(selectedRegion);

        if (selectedRegion === 'EU') {
            setCurrentData(dataEu);
        } else if (selectedRegion === 'US_EAST') {
            setCurrentData(dataUs);
        } else if (selectedRegion === 'APAC') {
            setCurrentData(dataAp);
        }
    };

    useEffect(() => {
        if (
            (!fullData || fullData.length === 0)
        ) {
            setLoading(true);
        } else {
            setLoading(false);

            setDataEu(fullData.find(item => item.key === 'EU')?.value || []);
            setDataUs(fullData.find(item => item.key === 'US_EAST')?.value || []);
            setDataAp(fullData.find(item => item.key === 'APAC')?.value || []);

            setCurrentData(fullData.find(item => item.key === 'EU')?.value || []);

        }
    }, [fullData, dateLabels]);


    return (
        <div
        >
            <Typography
                variant="h5"
                component="h5"
                align="left"
                sx={{
                    padding: 1,
                    paddingBottom: 4,
                }}
            >{nameTitle}</Typography>
            {loading ? (
                <Grid item xs={12} md={12}>
                    <Box justifyContent="center" textAlign="center" alignItems="center">
                        <CircularProgress size={300}/>
                    </Box>
                </Grid>
            ) : (
                <Grid item xs={12} md={12}>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={region}
                        label="Region"
                        onChange={handleChange}
                        sx={{
                            marginBottom: '20px',
                        }}
                    >
                        <MenuItem value={'EU'}>EU</MenuItem>
                        <MenuItem value={'US_EAST'}>US_EAST</MenuItem>
                        <MenuItem value={'APAC'}>APAC</MenuItem>
                    </Select>

                    <LineChart
                        xAxis={[
                            {
                                dataKey: 'date',
                                scaleType: 'point'
                            },
                        ]}
                        series={[
                            {
                                dataKey: 'incoming_requests',
                                label: 'Incoming Requests',
                                area: true,
                                showMark: false
                            },
                            {
                                dataKey: 'outgoing_requests',
                                label: 'Outgoing Requests',
                                area: true,
                                showMark: false
                            },
                            {
                                dataKey: 'valid_qps',
                                label: 'Valid QPS',
                                area: true,
                                showMark: false
                            },
                        ]}
                        dataset={currentData}
                        colors={colorCategories["Accent"]}
                        slotProps={{ legend: { hidden: true } }}
                        height="400"
                        width="1430"
                        margin={{
                            top: 10,
                            right: 10,
                            bottom: 50,
                            left: 50,
                        }}
                    />
                </Grid>
                    )}
</div>
)
    ;
};

export default LineQpsChart;