import { ListItemButton, ListItemIcon } from "@mui/material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import colorConfigs from "../../configs/colorConfigs";

const SidebarItem = ({ item, onMenuItemClick }) => {
    const { appState } = useSelector((state) => state.appState);

    const handleClick = () => {
        onMenuItemClick(item);
    };

    return (
        item.sidebarProps && item.path ? (
            <ListItemButton
                component={Link}
                to={item.path}
                onClick={handleClick}
                sx={{
                    "&:hover": {
                        backgroundColor: colorConfigs.sidebar.hoverBg
                    },
                    backgroundColor: appState === item.state ? colorConfigs.sidebar.activeBg : "unset",
                    paddingY: "10px",
                    paddingX: "40px",
                }}
            >
                <ListItemIcon sx={{
                    color: colorConfigs.sidebar.color,
                    marginX: "-15%",
                    paddingX: "40px",
                }}>
                    {item.sidebarProps.icon && item.sidebarProps.icon}
                </ListItemIcon>
                {item.sidebarProps.displayText}
            </ListItemButton>
        ) : null
    );
};


export default SidebarItem;