import React, {useState, useEffect, useRef} from 'react';
import {styled} from '@mui/material/styles';
import {
    Grid,
    Typography,
    Select,
} from '@mui/material';
import {DateRangePicker} from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const DateSelectionCalendar = ({ selectedDates, setSelectedDates }) => {

    const [showCalendar, setShowCalendar] = useState(false);
    const dropDownRef = useRef(null);
    const pickerRef = useRef(null);

    const handleDateChange = (event) => {
        const endDate = new Date(event.range1.endDate);
        endDate.setHours(12, 12, 12);

        const startDate = new Date(event.range1.startDate);
        startDate.setHours(12, 12, 12);

        setSelectedDates({
            startDate: startDate,
            endDate: endDate,
        });
    };

    const handleClickOutside = (event) => {
        if (pickerRef.current && !pickerRef.current.contains(event.target) && !dropDownRef.current.contains(event.target)) {
            setShowCalendar(false);
        }
    };

    const toggleCalendar = () => {
        setShowCalendar((prev) => !prev);
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (
                pickerRef.current &&
                !pickerRef.current.contains(event.target) &&
                dropDownRef.current &&
                !dropDownRef.current.contains(event.target)
            ) {
                setShowCalendar(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [pickerRef, dropDownRef]);

    return (
        <div style={{ position: 'relative' }}>
            <div
                ref={dropDownRef}
                style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    userSelect: 'none',
                    borderRadius: '5px',
                    textAlign: 'center',
                    backgroundColor: '#f0f3f5',
                    padding: '10px',
                    cursor: 'pointer',
                    border: '1px solid #dddfe1',
                }}
                onClick={toggleCalendar}
            >
                <div>Date Range</div>
                <div>
                    {selectedDates?.startDate
                        ? selectedDates.startDate.toISOString().split('T')[0]
                        : 'N/A'}{' '}
                    -{' '}
                    {selectedDates?.endDate
                        ? selectedDates.endDate.toISOString().split('T')[0]
                        : 'N/A'}
                </div>
            </div>
            {showCalendar && (
                <div
                    ref={pickerRef}
                    style={{
                        position: 'absolute',
                        top: '50px',
                        right: '10px',
                        zIndex: 1000,
                        backgroundColor: '#fff',
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                        borderRadius: '8px',
                        border: '1px solid #ddd',
                    }}
                >
                    <DateRangePicker
                        ranges={[
                            selectedDates || {
                                startDate: new Date(),
                                endDate: new Date(),
                                key: 'selection',
                            },
                        ]}
                        onChange={handleDateChange}
                        moveRangeOnFirstSelection={false}
                        showSelectionPreview={true}
                    />
                </div>
            )}
        </div>


    )
}

export default DateSelectionCalendar;