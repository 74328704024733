import React, {useEffect, useState, useRef} from 'react';
import {DataGrid, GridToolbar, useGridApiRef} from '@mui/x-data-grid';
import {
    Checkbox,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Alert,
    AlertTitle,
    FormControlLabel,
    InputLabel,
    FormControl,
    Select,
    MenuItem,
    OutlinedInput,
    Box,
} from '@mui/material';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import FileDialog from '../../../../components/dialog/FileDialog';
import {fetchAccManagers} from '../../../../API/AdminUserControl';
import {fetchDspCompanies} from '../../../../API/FinancialTools';
import ApproveRowDialog from '../components/ApproveRowDialog';
import DeleteRowDialog from '../components/DeleteRowDialog';
import {sendDataToApi} from '../../../../API/apiHelper';
import EditRowDialog from '../components/EditRowDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import useApi from '../../../../hooks/useApi';
import {styled} from '@mui/system';
import Cookies from 'js-cookie';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 300,
        },
    },
};

const StyledDataGrid = styled(DataGrid)(({theme}) => ({
    '& .MuiDataGrid-row': {
        '&:nth-child(even)': {
            backgroundColor: '#eaf0f5',
        },
    },
    '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 'bold',
    },
    '& .negative.win_rate_per, .negative.revenue_per, .negative.fill_rate_per, .negative.profit_per': {
        backgroundColor: 'rgba(255,235,235)',
    },
    '& .positive.win_rate_per, .positive.revenue_per, .positive.fill_rate_per, .positive.profit_per': {
        backgroundColor: 'rgba(235,255,235)',
    },
    '& .MuiDataGrid-cell': {
        whiteSpace: 'normal !important',
        wordWrap: 'break-word',
    },
    '& .total-row': {
        backgroundColor: '#f8fff9',
        fontWeight: 'bold',
    },
}));

function processData(data) {
    if (!data || !Array.isArray(data) || data.length === 0) return [];

    return data.map(row => {
        const processedRow = { id: row.value.id, ...row.value };

        if (row.value.advertiser) {
            processedRow.advertiserDisplay = row.value.advertiser.name;
            processedRow.advertiserOriginal = row.value.advertiser.id;
        }

        if (row.value.account_manager) {
            processedRow.accManagerDisplay = row.value.account_manager.name;
            processedRow.accManagerOriginal = row.value.account_manager.id;
        }

        if (row.value.platform) {
            processedRow.platformDisplay = row.value.platform.charAt(0).toUpperCase() + row.value.platform.slice(1);
        }

        if (row.value.irtb_status) {
            processedRow.irtb_statusDisplay = row.value.irtb_status.charAt(0).toUpperCase() + row.value.irtb_status.slice(1);
        }

        if (row.value.autoload === 0 || row.value.autoload === 1) {
            processedRow.autoloadDisplay = row.value.autoload === 0 ? 'No' : 'Yes';
        }

        return processedRow;
    });
}



const TotalRevenueDataGrid = ({data, onRowEdit}) => {
    const gridRef = useRef(null);
    const [rows, setRows] = useState(processData(data));
    const [open, setOpen] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [openApprove, setOpenApprove] = useState(false);
    const [openFile, setOpenFile] = useState(false);
    const [openSend, setOpenSend] = useState(false);
    const [editRow, setEditRow] = useState({});
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [users, setUsers] = useState([]);

    const {data: dspCompany, loading: loadingCompany} = useApi(fetchDspCompanies);

    useEffect(() => {
        setRows(processData(data));
    }, [data]);

    useEffect(() => {
        const fetchUsers = async () => {
            const response = await fetchAccManagers();
            setUsers(response);
        };

        fetchUsers();
    }, []);

    const platform = rows.some(row => 'platform' in row);
    const advertiser = rows.some(row => 'advertiser' in row);
    const hyper_status = rows.some(row => 'hyper_status' in row);
    const irtb_status = rows.some(row => 'irtb_status' in row);
    const revenue_from_the_system = rows.some(row => 'revenue_from_the_system' in row);
    const final_revenue = rows.some(row => 'final_revenue' in row);
    const deduction = rows.some(row => 'deduction' in row);
    const profit = rows.some(row => 'profit' in row);
    const profit_percentage = rows.some(row => 'profit_percentage' in row);
    const account_manager = rows.some(row => 'account_manager' in row);
    const revision_comment = rows.some(row => 'revision_comment' in row);
    const file = rows.some(row => 'file' in row);
    const aws_file = rows.some(row => 'aws_file' in row);
    const added_by = rows.some(row => 'added_by' in row);
    const added_at = rows.some(row => 'added_at' in row);
    const period = rows.some(row => 'period' in row);
    const cost = rows.some(row => 'cost' in row);
    const approved_by = rows.some(row => 'approved_by' in row);
    const proforma_id = rows.some(row => 'proforma_id' in row);
    const autoloadDisplay = rows.some(row => 'autoloadDisplay' in row);

    const userAdmin = Cookies.get('user_admin');
    const userManager = Cookies.get('user_manager');
    const boosOfAcManager = Cookies.get('boss_ac_managers');

    const columns = [];

    columns.push(
        {
            field: 'action',
            headerName: '',
            width: 100,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                const canEdit = (userAdmin === '1') ||
                    (boosOfAcManager === '1' && params.row.irtb_status === 'new') ||
                    (userManager === '1' && params.row.accManagerOriginal == localStorage.getItem('user_id') && params.row.irtb_status === 'new');
                if (params.row.period) {
                    return (
                        <Button
                            variant="outlined"
                            startIcon={<EditIcon/>}
                            color="primary"
                            size="medium"
                            onClick={() => handleEditButtonClick(params.row)}
                            disabled={!canEdit}
                        >
                            Edit
                        </Button>
                    );
                }
            },
        }
    );

    columns.push({
        field: 'action 2',
        headerName: '',
        width: 130,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
            const canEdit = (userAdmin === '1') ||
                (boosOfAcManager === '1' && params.row.irtb_status === 'new') ||
                (userManager === '1' && params.row.accManagerOriginal == localStorage.getItem('user_id') && params.row.irtb_status === 'new');
            if (params.row.period) {
                return (
                    <Button
                        variant="outlined"
                        startIcon={<AssignmentTurnedInIcon/>}
                        color="success"
                        size="medium"
                        onClick={() => handleApproveButtonClick(params.row)}
                        disabled={!canEdit}
                    >
                        Approve
                    </Button>
                );
            }
        },
    });

    columns.push({
        field: 'action 3',
        headerName: '',
        width: 130,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
            const canEdit = (userAdmin === '1') ||
                (boosOfAcManager === '1' && params.row.irtb_status === 'new') ||
                (userManager === '1' && params.row.accManagerOriginal == localStorage.getItem('user_id') && params.row.irtb_status === 'new');
            if (params.row.period) {
                return (
                    <Button
                        variant="outlined"
                        startIcon={<DeleteIcon/>}
                        color="error"
                        size="medium"
                        onClick={() => handleDeleteButtonClick(params.row)}
                        disabled={!canEdit}
                    >
                        Delete
                    </Button>
                );
            }
        },
    });

    columns.push({field: 'id', headerName: 'ID', width: 80});

    if (rows.some(row => row.period)) {
        columns.push({ field: 'period', headerName: 'Period', width: 80 });
    }

    if (rows.some(row => row.advertiserDisplay)) {
        columns.push({ field: 'advertiserDisplay', headerName: 'Advertiser', width: 250 });
    }

    if (rows.some(row => row.platformDisplay)) {
        columns.push({ field: 'platformDisplay', headerName: 'Platform', width: 100 });
    }

    if (rows.some(row => row.irtb_statusDisplay)) {
        columns.push({ field: 'irtb_statusDisplay', headerName: 'iRTB Status', width: 100 });
    }

    if (rows.some(row => row.cost)) columns.push({field: 'cost', headerName: 'Cost, $', width: 100});
    if (rows.some(row => row.profit)) columns.push({field: 'profit', headerName: 'Profit, $', width: 100});
    if (rows.some(row => row.profit_percentage)) columns.push({field: 'profit_percentage', headerName: 'Profit, %', width: 100});
    if (rows.some(row => row.revenue_from_the_system)) columns.push({
        field: 'revenue_from_the_system',
        headerName: 'Revenue from the system, $',
        width: 200
    });
    if (rows.some(row => row.final_revenue)) columns.push({field: 'final_revenue', headerName: 'Final revenue, $', width: 150});


    if (file || aws_file) columns.push({
        field: 'action 4',
        headerName: 'File',
        width: 130,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
            const canEdit = params.row.file || params.row.aws_file;
            if (params.row.period) {
                return (
                    <Button
                        variant="outlined"
                        startIcon={<FileCopyOutlinedIcon/>}
                        color="primary"
                        size="medium"
                        onClick={() => handleFileButtonClick(params.row)}
                        disabled={!canEdit}
                    >
                        File
                    </Button>
                );
            }
        },
    });
    if (proforma_id) columns.push({field: 'proforma_id', headerName: 'Proforma ID', width: 100});
    if (account_manager) columns.push({field: 'accManagerDisplay', headerName: 'Account manager', width: 150});
    if (autoloadDisplay) columns.push({field: 'autoloadDisplay', headerName: 'Autoload', width: 150});
    if (added_by) columns.push({field: 'added_by', headerName: 'Added by', width: 150});
    if (added_at) columns.push({field: 'added_at', headerName: 'Added at', width: 150});
    if (revision_comment) columns.push({field: 'revision_comment', headerName: 'Revision comment', width: 150});
    if (approved_by) columns.push({field: 'approved_by', headerName: 'Approved by', width: 150});

    const handleEditButtonClick = (row) => {
        setEditRow(row);
        setOpen(true);
    };

    const handleDeleteButtonClick = (row) => {
        setEditRow(row);
        setOpenDelete(true);
    };

    const handleApproveButtonClick = (row) => {
        setEditRow(row);
        setOpenApprove(true);
    };

    const handleFileButtonClick = (row) => {
        setEditRow(row);
        setOpenFile(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDeleteClose = () => {
        setOpenDelete(false);
    };

    const handleFileClose = () => {
        setOpenFile(false);
    };

    const handleApproveClose = () => {
        setOpenApprove(false);
    };

    const handleCloseSend = () => {
        setOpenSend(false);
    };

    const openSendSuccess = () => {
        setOpenSend(true);
    };

    const handleRowDelete = (deletedRowId) => {
        setRows(rows.filter(row => row.id !== deletedRowId));
    };

    const handleRowEdit = () => {
        onRowEdit();
    };

    if (data.length === 0) {
        return null;
    } else {

        return (

            <div style={{height: 800, width: '100%'}} ref={gridRef}>
                <StyledDataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={25}
                    getRowClassName={(params) => (params.row.total ? 'total-row' : '')}
                    slots={{
                        toolbar: GridToolbar,
                    }}
                    disableRowSelectionOnClick
                />

                <EditRowDialog
                    open={open}
                    handleClose={handleClose}
                    dsp={dspCompany}
                    editRow={editRow}
                    onSubmitSuccess={handleRowEdit}
                />

                <DeleteRowDialog
                    open={openDelete}
                    handleClose={handleDeleteClose}
                    editRow={editRow}
                    onDeleteSuccess={handleRowDelete}
                />

                <ApproveRowDialog
                    open={openApprove}
                    handleClose={handleApproveClose}
                    editRow={editRow}
                    onApproveSuccess={handleRowEdit}
                />

                <FileDialog
                    open={openFile}
                    handleClose={handleFileClose}
                    firstLink={editRow.file || ''}
                    secondLink={editRow.aws_file || ''}
                />
            </div>
        );
    }
};

export default TotalRevenueDataGrid;
