export const colorCategories = {
    Pastel1: [
        '#fbb4ae',
        '#b3cde3',
        '#ccebc5',
        '#decbe4',
        '#fed9a6',
        '#ffffcc',
        '#e5d8bd',
        '#fddaec',
        '#f2f2f2',
    ],
    Pastel2: [
        '#b3e2cd',
        '#fdcdac',
        '#cbd5e8',
        '#f4cae4',
        '#e6f5c9',
        '#fff2ae',
        '#f1e2cc',
        '#cccccc',
    ],
    Accent: [
        '#173a5e',
        '#c91b65',
        '#2fa3a0',
        '#7fc97f',
        '#ffff99',
        '#386cb0',
        '#f0027f',
        '#bf5b17',
        '#666666',
    ],
    Paired: [
        '#a6cee3',
        '#fb9a99',
        '#b2df8a',
        '#33a02c',
        '#1f78b4',
        '#ffff98',
        '#14795c',
        '#e31a1c',
        '#fdbf6f',
        '#ff7f00',
        '#cab2d6',
        '#6a3d9a',
        '#ffff99',
        '#b15928',
    ],
};
