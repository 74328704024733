import { Avatar, Drawer, List, Stack, Toolbar } from "@mui/material";
import assets from "../../assets";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import appRoutes from "../../routes/appRoutes";
import { useNavigate } from "react-router-dom";
import SidebarItem from "./SidebarItem";
import SidebarItemCollapse from "./SidebarItemCollapse";
import { useEffect, useState } from "react";
import { Divider, Chip } from "@mui/material";
//import Cookies from 'js-cookie';

const Sidebar = ({ isOpen, handleDrawerOpen, onMenuItemClick }) => {
    const [openItem, setOpenItem] = useState(null);
    const navigate = useNavigate();
    // const [isAdmin, setIsAdmin] = useState(false);
    //
    // useEffect(() => {
    //     const admin = Cookies.get('user_admin');
    //
    //     if(admin === 1){
    //         setIsAdmin(true);
    //     }
    // }, []);

    const handleAvatarClick = () => {
        navigate("/");
    };
    const handleOpen = (id) => {
        setOpenItem((prevOpenItem) => (prevOpenItem === id ? null : id));
    };

    useEffect(() => {
        if (!isOpen) {
            setOpenItem(null);
        }
    }, [isOpen]);

    return (
        <Drawer
            variant="permanent"
            sx={{
                width: isOpen ? sizeConfigs.sidebar.width : sizeConfigs.sidebar.minWidth,
                flexShrink: 1,
                "& .MuiDrawer-paper": {
                    width: isOpen ? sizeConfigs.sidebar.width : sizeConfigs.sidebar.minWidth,
                    boxSizing: "border-box",
                    borderRight: "0px",
                    backgroundColor: colorConfigs.sidebar.bg,
                    color: colorConfigs.sidebar.color,
                    //transition: "width 0.2s"
                },
            }}
            onClick={!isOpen ? handleDrawerOpen : undefined}
        >
            <List disablePadding>
                <Toolbar sx={{ marginBottom: "20px" }}>
                    <Stack sx={{ width: "100%" }} direction="row" justifyContent="center">
                        {isOpen ? (
                            <Avatar sx={{
                                paddingX: "20px",
                                width: "100%",
                                height: "100%",
                                transition: "transform 0.3s ease-in-out",
                                "&:hover": {
                                    transform: "scale(0.9)",
                                    cursor: "pointer",
                                },
                            }} src={assets.images.logo} variant="square" onClick={handleAvatarClick} />
                        ) : (
                            <Avatar
                                sx={{
                                    marginLeft: "10px",
                                    width: "200%",
                                    height: "200%",
                                    transition: "transform 0.3s ease-in-out",
                                    "&:hover": {
                                        transform: "scale(0.9)",
                                        cursor: "pointer",
                                    },
                                }}
                                src={assets.images.smallLogo}
                                variant="square"
                                onClick={handleAvatarClick}
                            />
                        )}
                    </Stack>
                </Toolbar>
                {appRoutes.map((route, index) => (
                    <>
                        {route.sidebarProps && route.state == "Admin"
                            &&
                            <Divider sx={{ marginTop: 2, marginBottom: 2 }}>

                            </Divider>
                        }
                        {route.child ? (
                            <SidebarItemCollapse
                                item={route}
                                key={index}
                                onOpenChange={handleOpen}
                                sidebarOpen={isOpen}
                                onMenuItemClick={onMenuItemClick}
                            />
                        ) : (
                            <SidebarItem
                                item={route}
                                key={index}
                                onMenuItemClick={onMenuItemClick}
                            />
                        )}
                    </>
                ))}
            </List>
        </Drawer>
    );
};

export default Sidebar;
