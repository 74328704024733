import { AppBar, IconButton, Toolbar, Typography, Button, Menu, MenuItem, Avatar } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import MenuIcon from '@mui/icons-material/Menu';
import { MouseEventHandler, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from 'js-cookie';

const Topbar = ({ onMenuClick, isOpen, selectedMenuItem }) => {

    const [isHovered, setIsHovered] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const routerHistory = useNavigate();

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleProfileClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleProfileClose = () => {

        setAnchorEl(null);
    };

    const handleLogout = () => {
        sessionStorage.clear();
        localStorage.clear();
        Cookies.remove('jwt');
        Cookies.remove('user_admin');
        Cookies.remove('user_manager');
        Cookies.remove('user_pub_manager');
        Cookies.remove('boss_ac_managers');
        Cookies.remove('boss_pub_managers');
        setAnchorEl(null);
        routerHistory('/welcome');
    };

    const location = useLocation();
    const pathSegments = location.pathname.split('/').filter(Boolean);
    const lastSegment = (pathSegments[pathSegments.length - 1] || 'Dashboard').replace(/-/g, ' ');

    // const username = localStorage.getItem('user_name');
    // const usermail = localStorage.getItem('user_email')

    const avatarGoogle = localStorage.getItem('userGooglePicture');
    //const usermail = localStorage.getItem('user_email');
    const usernameGoogle = localStorage.getItem('userGoogleName');
    let username = usernameGoogle ? usernameGoogle.replace(/"/g, '') : localStorage.getItem('user_name');
    let avatar = avatarGoogle ? avatarGoogle.replace(/"/g, '') : 'https://cdn-icons-png.flaticon.com/512/247/247783.png';

    useEffect(() => {
        document.title = `${lastSegment} - iRTB`;
    }, [lastSegment]);

    return (
        <AppBar
            position="fixed"
            sx={{
                width: `calc(100% - ${isOpen ? sizeConfigs.sidebar.width : sizeConfigs.sidebar.minWidth})`,
                ml: isOpen ? sizeConfigs.sidebar.width : sizeConfigs.sidebar.minWidth,
                flexShrink: 1,
                backgroundColor: colorConfigs.topbar.bg,
                color: colorConfigs.topbar.color,
                transition: "width 0.2s",
                boxShadow: '0 0 8px rgba(0, 0, 0, 0.1)',
            }}
        >
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={onMenuClick}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    sx={{
                        marginRight: '36px',
                        transform: isHovered ? 'scale(1.2)' : 'scale(1)',
                        transition: 'transform 0.2s'
                    }}
                >
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6">
                    {lastSegment}
                </Typography>
                <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                    <Button color="inherit" onClick={handleProfileClick} style={{ textTransform: 'none' }}>
                        <Avatar src={avatar} alt="Avatar" style={{ marginRight: '10px' }}/>
                        {username}
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleProfileClose}
                    >
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </Menu>
                </div>
            </Toolbar>
        </AppBar>
    );
};

export default Topbar;
