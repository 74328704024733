import React from 'react';
import {Grid, Typography} from "@mui/material";
import HomeDashboard from "./components/HomeDashboard";

const HomePage = () => {

    return (
        <div>
            <HomeDashboard/>

        </div>
    );
};

export default HomePage;