import React from 'react';
import {
    Typography,
    Avatar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Divider
} from '@mui/material';
import countryNames from './country-names.json';
import Flag from "react-world-flags";

const TopCountiesList = ({ countries = [] }) => {
    const validCountries = Array.isArray(countries) ? countries : [];

    return (
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {validCountries.slice(0, 10).map((country, index) => {
                const fullName = Object.entries(countryNames).find(
                    ([_, details]) => details.alpha3 === country.name
                )?.[0] || "Unknown";

                return (
                    <React.Fragment key={country.name}>
                        <ListItem alignItems="flex-start">
                            <ListItemAvatar>
                                <Avatar sx={{ width: 40, height: 40, boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)', overflow: 'hidden' }}>
                                    <Flag
                                        code={country.name}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover',
                                        }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={fullName}
                                secondary={
                                    <Typography
                                        component="span"
                                        variant="body2"
                                        sx={{ color: 'text.primary', display: 'inline' }}
                                    >
                                        Revenue: <br/>
                                        $ {country.value.toLocaleString()}
                                    </Typography>
                                }
                            />
                        </ListItem>
                        {index < 9 && <Divider variant="inset" component="li" />}
                    </React.Fragment>
                );
            })}
        </List>
    );
};

export default TopCountiesList;
