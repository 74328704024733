import React, { useState, useEffect } from 'react';
import {LineChart} from '@mui/x-charts/LineChart';
import { Grid, Typography, Box, CircularProgress } from "@mui/material";
import {mangoFusionPalette} from '@mui/x-charts/colorPalettes';
import { colorCategories } from './colors';

const LineRevenueChart = ({firstData, firstLable, secondData, secondLable, dateLabels, nameTitle}) => {

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (
            (!firstData || firstData.length === 0) ||
            (!secondData || secondData.length === 0) ||
            (!dateLabels || dateLabels.length === 0)
        ) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [firstData, secondData, dateLabels]);

    return (
        <div
        >
            <Typography
                variant="h5"
                component="h5"
                align="left"
                sx={{
                    padding: 1,
                    paddingBottom: 4,
                }}
            >{nameTitle}</Typography>
            {loading ? (
                <Grid item xs={12} md={12}>
                    <Box justifyContent="center" textAlign="center" alignItems="center">
                        <CircularProgress size={300} />
                    </Box>
                </Grid>
            ) : (
                <LineChart
                    series={[
                        { data: firstData, label: firstLable },
                        { data: secondData, label: secondLable },
                    ]}
                    xAxis={[{ scaleType: 'point', data: dateLabels }]}
                    colors={mangoFusionPalette}
                    margin={{ left: 65 }}
                    width="700"
                    height="400"
                />
            )}
        </div>
    );
};

export default LineRevenueChart;