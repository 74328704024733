import React, {useState, useEffect, useRef} from 'react';
import {styled} from '@mui/material/styles';
import {
    Grid,
    Typography,
    MenuItem,
    Select,
    Box,
    CircularProgress
} from '@mui/material';
import {ComposableMap, Geographies, Geography, Graticule} from 'react-simple-maps';
import DateSelectionCalendar from './DateSelectionCalendar';
import TopCountiesList from './TopCountiesList';
import HtmlTooltip from './HtmlTooltip';
import {sendDataToApi} from '../../../API/apiHelper';

const geoUrl = '/data/world-countries.json';

const MainMap = () => {
    const [loading, setLoading] = useState(false);

    const [dataAll, setDataAll] = useState([]);
    const [dataEu, setDataEu] = useState([]);
    const [dataUs, setDataUs] = useState([]);
    const [dataApac, setDataApac] = useState([]);

    const [currentData, setCurrentData] = useState([]);
    const [requestData, setRequestData] = useState([]);

    const [rankedCountry, setRankedCountry] = useState([]);
    const [rankedCountryData, setRankedCountryData] = useState([]);

    const [region, setRegion] = useState('ALL');

    const [tooltipContent, setTooltipContent] = useState('');
    const baseColor = {r: 10, g: 70, b: 131};

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const formattedDate = yesterday.toISOString().split('T')[0];

    const [selectedDates, setSelectedDates] = useState({
        startDate: new Date(formattedDate),
        endDate: new Date(formattedDate),
    });

    const lightenColor = (color, factor) => {
        return `rgb(
            ${Math.round(color.r + (255 - color.r) * factor)},
            ${Math.round(color.g + (255 - color.g) * factor)},
            ${Math.round(color.b + (255 - color.b) * factor)}
        )`;
    };

    useEffect(() => {
        setRequestData(
            {
                startDate: new Date(selectedDates.startDate).toISOString(),
                endDate: new Date(selectedDates.endDate).toISOString(),
            }
        )
    }, [selectedDates]);

    useEffect(() => {
        setLoading(true)
        fetchGeoData();
    }, [requestData]);


    useEffect(() => {
        setRankedCountry(
            Object.entries(currentData)
                .sort((a, b) => b[1].value - a[1].value)
                .map(([name, data], index) => ({
                    name,
                    ...data,
                    color: lightenColor(baseColor, index * 0.05),
                }))
        );
        const rankedCountries = Object.entries(currentData)
            .sort((a, b) => b[1].value - a[1].value)
            .map(([name, data], index) => ({
                name,
                ...data,
                color: lightenColor(baseColor, index * 0.02),
            }));

        setRankedCountryData(
            Object.fromEntries(
                rankedCountries.map(({name, ...rest}) => [name, rest])
            )
        );
    }, [currentData]);


    const handleChange = (event) => {
        const selectedRegion = event.target.value;
        setRegion(selectedRegion);

        if (selectedRegion === 'EU') {
            setCurrentData(dataEu);
        } else if (selectedRegion === 'US_EAST') {
            setCurrentData(dataUs);
        } else if (selectedRegion === 'ALL') {
            setCurrentData(dataAll);
        } else if (selectedRegion === 'APAC') {
            setCurrentData(dataApac);
        }
    };

    const fetchGeoData = async () => {
        const formData = new FormData();
        formData.append('user_id', localStorage.getItem('user_id'));

        const response = await sendDataToApi('post', '/api/charts/revenue/by-geo', requestData);

        const fieldsArray = Object.entries(response.data).map(([key, value]) => ({key, value}));

        setCurrentData(fieldsArray.find(item => item.key === 'ALL')?.value || [])
        setDataAll(fieldsArray.find(item => item.key === 'ALL')?.value || [])
        setDataEu(fieldsArray.find(item => item.key === 'EU')?.value || [])
        setDataUs(fieldsArray.find(item => item.key === 'US_EAST')?.value || [])
        setDataApac(fieldsArray.find(item => item.key === 'APAC')?.value || [])

        setLoading(false)
    };

    useEffect(() => {
        fetchGeoData();
    }, []);

    return (
        <Grid container spacing={2}>
            <Grid item xs={10}
                  sx={{
                      backgroundColor: 'white',
                      borderRadius: '8px',
                      border: '4px',
                      boxShadow: '0 0 10px rgba(0, 0, 0, 0.08)',
                      marginRight: '-15px',
                      marginLeft: '-20px',
                      ml: 0,
                  }}
            >
                <DateSelectionCalendar
                    selectedDates={selectedDates}
                    setSelectedDates={setSelectedDates}
                />

                <Typography variant="h5" sx={{padding: 1, paddingBottom: 2}}>
                    Revenue By Countries
                </Typography>

                {currentData.length === 0 || loading ? (
                    <Grid item xs={12} md={12}>
                        <Box justifyContent="center" textAlign="center" alignItems="center">
                            <CircularProgress size={300}/>
                        </Box>
                    </Grid>
                ) : (
                    <>
                        <Select value={region} label="Data centers" onChange={handleChange} sx={{marginLeft: '10px'}}>
                            <MenuItem value={'EU'}>EU</MenuItem>
                            <MenuItem value={'US_EAST'}>US_EAST</MenuItem>
                            <MenuItem value={'APAC'}>APAC</MenuItem>
                            <MenuItem value={'ALL'}>ALL</MenuItem>
                        </Select>

                        <ComposableMap style={{marginTop: '-90px'}}>
                            <Graticule stroke="#EFEFEF"/>
                            <Geographies geography={geoUrl}>
                                {({geographies}) =>
                                    geographies.map((geo) => {
                                        const {name} = geo.properties;
                                        const {id} = geo;
                                        const countryInfo = rankedCountryData[id];

                                        return (
                                            <HtmlTooltip
                                                key={geo.rsmKey || id}
                                                title={
                                                    countryInfo ? (
                                                        <div>
                                                            <strong>{name}</strong>
                                                            <br/>
                                                            Value: {countryInfo.value.toLocaleString()}
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <strong>{name || 'Unknown'}</strong>
                                                        </div>
                                                    )
                                                }
                                                arrow
                                            >
                                                <Geography
                                                    geography={geo}
                                                    onMouseEnter={() => {
                                                        if (countryInfo) {
                                                            setTooltipContent(
                                                                `${name}: Value ${countryInfo.value.toLocaleString()}`
                                                            );
                                                        } else {
                                                            setTooltipContent(name || 'Unknown');
                                                        }
                                                    }}
                                                    onMouseLeave={() => {
                                                        setTooltipContent('');
                                                    }}
                                                    style={{
                                                        default: {
                                                            fill: countryInfo?.color || '#D2D4D6',
                                                            outline: 'none',
                                                        },
                                                        hover: {fill: '#173a5e', outline: 'none'},
                                                        pressed: {fill: '#173a5e', outline: 'none'},
                                                    }}
                                                    stroke="#FFFFFF"
                                                />
                                            </HtmlTooltip>
                                        );
                                    })
                                }
                            </Geographies>
                        </ComposableMap>
                    </>
                )}
            </Grid>

            <Grid item xs={2}
                  sx={{
                      padding: 2,
                      backgroundColor: 'white',
                      borderRadius: '8px',
                      boxShadow: '0 0 10px rgba(0, 0, 0, 0.08)',
                      marginRight: '-15px',
                      ml: 'auto',
                      width: '70%'
                  }}
            >
                <Typography variant="h6">Top 10 Countries</Typography>
                {currentData.length === 0 || loading ? (
                    <Grid item xs={12} md={12}>
                        <Box justifyContent="center" textAlign="center" alignItems="center">
                            <CircularProgress size={150}/>
                        </Box>
                    </Grid>
                ) : (
                    <>
                        <TopCountiesList
                            countries={rankedCountry}
                        />
                    </>
                )}
            </Grid>
        </Grid>
    );
};

export default MainMap;
