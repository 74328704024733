import React, {useState, useEffect} from 'react';
import {LineChart} from '@mui/x-charts/LineChart';
import {
    Grid,
    Typography,
    Box,
    CircularProgress,
    Select,
    MenuItem,
    Avatar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Divider
} from "@mui/material";
import countryNames from './map/country-names.json';
import Flag from "react-world-flags";

const osLogos = {
    Android: 'https://upload.wikimedia.org/wikipedia/commons/3/3e/Android_logo_2019.png',
    iOS: 'https://upload.wikimedia.org/wikipedia/commons/f/fa/Apple_logo_black.svg',
    Windows: 'https://upload.wikimedia.org/wikipedia/commons/4/48/Windows_logo_-_2021.svg',
    Linux: 'https://upload.wikimedia.org/wikipedia/commons/3/35/Tux.svg',
    macOS: 'https://upload.wikimedia.org/wikipedia/commons/f/fa/Apple_logo_black.svg',
    Unknown: 'https://upload.wikimedia.org/wikipedia/commons/a/ac/No_image_available.svg',
};

const BundleLists = ({budlesData}) => {

    const [loading, setLoading] = useState(false);

    const [dataByGeo, setDataByGeo] = useState([]);
    const [dataByOs, setDataByOs] = useState([]);
    const [dataBySize, setDataBySize] = useState([]);


    useEffect(() => {
        const geo = budlesData.find(item => item.key === 'geoName')?.value || [];
        const os = budlesData.find(item => item.key === 'osName')?.value || [];
        const size = budlesData.find(item => item.key === 'size')?.value || [];

        setDataByGeo(geo);
        setDataByOs(os);
        setDataBySize(size);

    }, [budlesData]);

    return (
        <Grid container spacing={2}>
            <Grid
                item
                xs={4}
                sx={{
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    border: '4px',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.08)',
                    marginRight: '-15px',
                    marginLeft: '-20px',
                    ml: 0,
                }}
            >
                <Typography  variant="h5" sx={{padding: 1, paddingBottom: 2}}>Top 10 Bundles By Size</Typography>

                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    {dataBySize.map((bundle, index) => (
                        <React.Fragment key={bundle.inventory_key}>
                            <ListItem alignItems="flex-start">
                                <ListItemText
                                    primary={
                                        <Typography
                                            variant="body1"
                                            sx={{ fontSize: '14px' }}
                                        >
                                            Bundle: <b>{bundle.inventory_key}</b>
                                        </Typography>
                                    }
                                    secondary={
                                        <>
                                            <Typography
                                                variant="body1"
                                                sx={{ fontSize: '14px', color: 'text.primary', }}
                                            >
                                                Size: <b>{bundle.size}</b>
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                sx={{
                                                    display: 'block',
                                                    color: 'text.primary',
                                                    fontSize: '14px',
                                                }}
                                            >
                                                Revenue: ${bundle.dsp_price.toLocaleString()}
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                sx={{
                                                    display: 'block',
                                                    color: 'text.primary',
                                                    fontSize: '14px',
                                                }}
                                            >
                                                Bid Floor: {bundle.avg_ssp_bid_floor.toLocaleString()}
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                sx={{
                                                    display: 'block',
                                                    color: 'text.primary',
                                                    fontSize: '14px',
                                                }}
                                            >
                                                Bid Price: ${bundle.avg_ssp_bid_price.toLocaleString()}
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                sx={{
                                                    display: 'block',
                                                    color: 'text.primary',
                                                    fontSize: '14px',
                                                }}
                                            >
                                                eCPM: {bundle.ssp_ecpm.toLocaleString()}
                                            </Typography>
                                        </>
                                    }
                                />
                            </ListItem>
                            {index < dataBySize.length - 1 && <Divider component="li" />}
                        </React.Fragment>
                    ))}
                </List>
            </Grid>
            
            <Grid
                item
                xs={4}
                sx={{
                    padding: 2,
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.08)',
                    marginRight: '-15px',
                    ml: 'auto',
                    width: '70%',
                }}
            >
                <Typography  variant="h5" sx={{padding: 1, paddingBottom: 2}}>Top 10 Bundles By Geo</Typography>

                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    {dataByGeo.map((bundle, index) => {
                        const fullName = Object.entries(countryNames).find(
                            ([_, details]) => details.alpha3 === bundle.geoName
                        )?.[0] || "Unknown";

                        return (
                            <React.Fragment key={bundle.inventory_key}>
                                <ListItem alignItems="flex-start" sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Avatar sx={{width: 55, height: 55, boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)', overflow: 'hidden' }}>
                                    <Flag
                                            code={bundle.geoName}
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                            }}
                                        />
                                    </Avatar>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant="body1"
                                                sx={{ fontSize: '14px' }}
                                            >
                                                Bundle: <b>{bundle.inventory_key}</b>
                                            </Typography>
                                        }
                                        secondary={
                                            <>
                                                <Typography
                                                    variant="body1"
                                                    sx={{
                                                        display: 'block',
                                                        color: 'text.primary',
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    Geo: <b>{fullName}</b>
                                                </Typography>
                                                <Typography
                                                    variant="body1"
                                                    sx={{
                                                        display: 'block',
                                                        color: 'text.primary',
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    Revenue: ${bundle.dsp_price.toLocaleString()}
                                                </Typography>
                                                <Typography
                                                    variant="body1"
                                                    sx={{
                                                        display: 'block',
                                                        color: 'text.primary',
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    Bid Floor: {bundle.avg_ssp_bid_floor.toLocaleString()}
                                                </Typography>
                                                <Typography
                                                    variant="body1"
                                                    sx={{
                                                        display: 'block',
                                                        color: 'text.primary',
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    Bid Price: ${bundle.avg_ssp_bid_price.toLocaleString()}
                                                </Typography>
                                                <Typography
                                                    variant="body1"
                                                    sx={{
                                                        display: 'block',
                                                        color: 'text.primary',
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    eCPM: {bundle.ssp_ecpm.toLocaleString()}
                                                </Typography>
                                            </>
                                        }
                                        sx={{ marginLeft: '20px' }}
                                    />
                                </ListItem>
                                {index < dataByGeo.length - 1 && <Divider component="li" />}
                            </React.Fragment>
                        );
                    })}
                </List>
            </Grid>



            <Grid
                item
                xs={4}
                sx={{
                    padding: 2,
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.08)',
                    marginRight: '-15px',
                    ml: 'auto',
                    width: '70%',
                }}
            >
                <Typography  variant="h5" sx={{padding: 1, paddingBottom: 2}}>Top 10 Bundles By OS</Typography>
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    {dataByOs.map((bundle, index) => {
                        const osLogo = osLogos[bundle.osName] || osLogos.Unknown;
                        return (
                            <React.Fragment key={bundle.inventory_key}>
                                <ListItem alignItems="flex-start" sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Avatar
                                        src={osLogo}
                                        alt={bundle.osName}
                                        sx={{width: 55, height: 55, boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)', overflow: 'hidden' }}
                                    />
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant="body1"
                                                sx={{ fontSize: '14px' }}
                                            >
                                                Bundle: <b>{bundle.inventory_key}</b>
                                            </Typography>
                                        }
                                        secondary={
                                            <>
                                                <Typography
                                                    variant="body1"
                                                    sx={{
                                                        display: 'block',
                                                        color: 'text.primary',
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    OS: <b>{bundle.osName}</b>
                                                </Typography>
                                                <Typography
                                                    variant="body1"
                                                    sx={{
                                                        display: 'block',
                                                        color: 'text.primary',
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    Revenue: ${bundle.dsp_price.toLocaleString()}
                                                </Typography>
                                                <Typography
                                                    variant="body1"
                                                    sx={{
                                                        display: 'block',
                                                        color: 'text.primary',
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    Bid Floor: {bundle.avg_ssp_bid_floor.toLocaleString()}
                                                </Typography>
                                                <Typography
                                                    variant="body1"
                                                    sx={{
                                                        display: 'block',
                                                        color: 'text.primary',
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    Bid Price: ${bundle.avg_ssp_bid_price.toLocaleString()}
                                                </Typography>
                                                <Typography
                                                    variant="body1"
                                                    sx={{
                                                        display: 'block',
                                                        color: 'text.primary',
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    eCPM: {bundle.ssp_ecpm.toLocaleString()}
                                                </Typography>
                                            </>
                                        }
                                        sx={{ marginLeft: '20px' }}
                                    />
                                </ListItem>
                                {index < dataByOs.length - 1 && <Divider component="li" />}
                            </React.Fragment>
                        );
                    })}
                </List>
            </Grid>


        </Grid>
    );
};

export default BundleLists;
