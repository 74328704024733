import React, {useState, useEffect} from 'react';
import Cookies from 'js-cookie';
import LineRevenueChart from "../../../components/charts/LineRevenueChart";
import LineQpsChart from "../../../components/charts/LineQpsChart";
import BarRevenueChart from "../../../components/charts/BarRevenueChart";
import BundleLists from "../../../components/charts/BundleLists";
import MainMap from "../../../components/charts/map/MainMap";
import {Grid, Typography} from "@mui/material";
import {fetchFullRevenueTotals, fetchQpsDaily, fetchQpsWasteful, fetchBundlesBy} from '../../../API/ChartsDashboard';
import {sendDataToApi} from '../../../API/apiHelper';


const HomeDashboard = () => {

    const [dspData, setDspData] = useState([]);
    const [sspData, setSspData] = useState([]);
    const [xLabels, setXLabels] = useState([]);

    const [qpsFull, setQpsFull] = useState([]);
    const [qpsWasteful, setQpsWasteful] = useState([]);
    const [bundlesByParamsData, setBundlesByParamsData] = useState([]);

    const userAdmin = Cookies.get('user_admin');
    const boosOfPubManager = Cookies.get('boss_pub_managers');
    const boosOfAcManager = Cookies.get('boss_ac_managers');

    useEffect(() => {

        const fetchQps = async () => {
            try {
                const fields = await fetchQpsDaily();

                setQpsFull(fields);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        const fetchBundlesByParams = async () => {
            try {
                const fields = await fetchBundlesBy();

                setBundlesByParamsData(fields);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        const fetchQpsWastefulDaily = async () => {
            try {
                const fields = await fetchQpsWasteful();

                setQpsWasteful(fields);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        const fetchFullTotals = async () => {
            try {
                const fields = await fetchFullRevenueTotals();

                const dsp = fields.find(item => item.key === 'DSP')?.value || [];
                const ssp = fields.find(item => item.key === 'SSP')?.value || [];

                setDspData(dsp.map(item => parseFloat(item.total_revenue)));
                setSspData(ssp.map(item => parseFloat(item.total_revenue)));
                setXLabels(dsp.map(item => item.date));
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        const fetchManagerTotals = async () => {
            const formData = new FormData();
            formData.append('user_id', localStorage.getItem('user_id'));

            const response = await sendDataToApi('post', '/api/charts/revenue/manager-totals', formData);

            const fieldsArray = Object.entries(response.data).map(([key, value]) => ({key, value}));

            const dsp = fieldsArray.find(item => item.key === 'DSP')?.value || [];
            const ssp = fieldsArray.find(item => item.key === 'SSP')?.value || [];

            setDspData(dsp.map(item => parseFloat(item.total_revenue)));
            setSspData(ssp.map(item => parseFloat(item.total_revenue)));
            setXLabels(dsp.map(item => item.date));
        };

        fetchBundlesByParams();

        fetchQps();
        fetchQpsWastefulDaily();

        if (userAdmin === '1' || boosOfAcManager === '1' || boosOfPubManager === '1') {
            fetchFullTotals();
        } else {
            fetchManagerTotals();
        }
    }, []);

    return (
        <div>
            <Grid container spacing={2}
                  sx={{
                      padding: 2,
                      borderRadius: '8px',
                      border: '4px',
                      paddingLeft: 0,
                      marginLeft: '-10px',
                  }}
            >

                <Grid item xs={13}
                      sx={{
                          marginBottom: '26px',
                          ml: 0,
                      }}
                >
                    <MainMap/>
                </Grid>

                <Grid item xs={6}
                      sx={{
                          padding: 2,
                          backgroundColor: 'white',
                          borderRadius: '8px',
                          border: '4px',
                          boxShadow: '0 0 10px rgba(0, 0, 0, 0.08)',
                      }}
                >
                    <LineRevenueChart
                        firstData={dspData}
                        firstLable={'DSP Revenue'}
                        secondData={sspData}
                        secondLable={'SSP Cost'}
                        dateLabels={xLabels}
                        nameTitle={'Revenue changes'}
                    />
                </Grid>

                <Grid item xs={6}
                      sx={{
                          padding: 2,
                          backgroundColor: 'white',
                          borderRadius: '8px',
                          boxShadow: '0 0 10px rgba(0, 0, 0, 0.08)',
                          marginRight: '-15px',
                          ml: 'auto',
                          width: '70%'
                      }}
                >
                    <BarRevenueChart
                        data={qpsWasteful}
                        nameTitle={'Top 10 Wasteful QPS'}
                    />
                </Grid>

                <Grid item xs={13}
                      sx={{
                          marginTop: '26px',
                          ml: 0,
                      }}
                >
                    <BundleLists
                        budlesData={bundlesByParamsData}
                    />
                </Grid>

                <Grid item xs={13}
                      sx={{
                          padding: 2,
                          backgroundColor: 'white',
                          borderRadius: '8px',
                          border: '4px',
                          boxShadow: '0 0 10px rgba(0, 0, 0, 0.08)',
                          marginTop: '26px',
                          marginRight: '-15px',
                          marginLeft: '-20px',
                          ml: 0,
                      }}
                >
                    <LineQpsChart
                        dateLabels={xLabels}
                        nameTitle="QPS Metrics (Last 24 hours)"
                        fullData={qpsFull}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default HomeDashboard;


