import React, { useState, useEffect } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { Grid, Typography, Box, CircularProgress, IconButton, Modal, Paper } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { mangoFusionPalette } from '@mui/x-charts/colorPalettes';
import HtmlTooltip from './map/HtmlTooltip';
import Cookies from 'js-cookie';

const BarRevenueChart = ({ data, nameTitle }) => {
    const [loading, setLoading] = useState(true);
    const [chartData, setChartData] = useState([]);
    const [chartLabels, setChartLabels] = useState([]);
    const [detailedData, setDetailedData] = useState([]);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [isModalOpen, setModalOpen] = useState(false);

    const userAdmin = Cookies.get('user_admin');
    const userId = localStorage.getItem('user_id');

    useEffect(() => {
        if (!data) {
            setLoading(true);
        } else {
            setLoading(false);

            let selectedRecords = [];
            let selectedRecordsFirst = [];
            if (userAdmin === '1') {
                selectedRecordsFirst = data.find(item => item.key === 'no_manager')?.value || [];
            } else if (userId) {
                selectedRecordsFirst = data.find(item => item.key === userId)?.value || [];
            }

            if (selectedRecordsFirst.records) {
                selectedRecords = selectedRecordsFirst.records;
            }

            if (selectedRecords.length === 0) {
                setChartData([]);
                setChartLabels([]);
                setDetailedData([]);
            } else {
                const topRecords = selectedRecords
                    .sort((a, b) => b.daylySpend - a.daylySpend)
                    .slice(0, 10);
                const labels = topRecords.map(record => record.name);
                const values = topRecords.map(record => record.daylySpend || 0);

                setChartLabels(labels);
                setChartData(values);
                setDetailedData(topRecords);
            }
        }
    }, [data]);

    const handleBarClick = (event, data) => {
        if (!data || typeof data.dataIndex === "undefined") {
            console.error("Invalid data received on bar click:", data);
            return;
        }
        setSelectedRecord(detailedData[data.dataIndex]);
        setModalOpen(true);
    };


    const handleCloseModal = () => {
        setModalOpen(false);
    };

    return (
        <div>
            <Grid container alignItems="center">
                <Typography
                    variant="h5"
                    component="h5"
                    align="left"
                    sx={{
                        padding: 1,
                        paddingBottom: 4,
                    }}
                >
                    {nameTitle}
                    <HtmlTooltip
                        title={
                            <div>
                                <strong>The information for the last 4 hours.</strong>
                                <br />These are bundles with the biggest revenue that
                                <br />exceed thresholds and have more than <strong>90% QPS usage.</strong>
                            </div>
                        }
                    >
                        <IconButton>
                            <InfoOutlinedIcon fontSize="medium" />
                        </IconButton>
                    </HtmlTooltip>
                </Typography>
            </Grid>
            {loading ? (
                <Grid item xs={12} md={12}>
                    <Box justifyContent="center" textAlign="center" alignItems="center">
                        <CircularProgress size={300} />
                    </Box>
                </Grid>
            ) : chartData.length > 0 ? (
                <BarChart
                    xAxis={[
                        {
                            scaleType: 'band',
                            data: chartLabels,
                        },
                    ]}
                    series={[
                        {
                            data: chartData,
                            label: 'Revenue',
                        },
                    ]}
                    colors={mangoFusionPalette}
                    borderRadius={8}
                    width="700"
                    height="400"
                    onAxisClick={handleBarClick}
                />
            ) : (
                <Typography variant="h6" align="center" sx={{ padding: 2 }}>
                    No Wasteful Bundles
                </Typography>
            )}

            <Modal open={isModalOpen} onClose={handleCloseModal}>
                <Paper
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.08)',
                        width: 400,
                        padding: 4,
                    }}
                >
                    {selectedRecord ? (
                        <div>
                            <Typography variant="h6" sx={{ marginBottom: 2 }}>
                                <b>Detailed Information:</b>
                            </Typography>
                            <Typography component="div"><strong>Name:</strong> {selectedRecord.name}</Typography>
                            <Typography component="div"><strong>Daily Spend:</strong> ${selectedRecord.daylySpend}</Typography>
                            <Typography component="div"><strong>Limit QPS:</strong> {selectedRecord.limitQPS}</Typography>
                            <Typography component="div"><strong>QPS Usage:</strong> {parseFloat((selectedRecord.realQPS / selectedRecord.limitQPS * 100).toFixed(2))}%</Typography>
                        </div>
                    ) : (
                        <Typography component="span">No data available.</Typography>
                    )}
                </Paper>
            </Modal>
        </div>
    );
};

export default BarRevenueChart;

